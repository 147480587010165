import React from "react";
import PageHeader from "../components/PageHeader";
import InnerHeadr from "../components/InnerHeader";
import rebrimg from "../images/rebr.svg";
import stepsimg from "../images/stepsinvlovd.png";
import stapa from "../images/stepa.png";
import forimg from "../images/fprimg.png";
import Marquee from "../components/Marquee";

const pageDetails = {
  head: {
    upper: "Insights, Ideas",
    lower: "and thoughts",
  },
  bottom: {
    normal: "DATA-DRIVEN APPROACH ",
    italic: "FOR BUSINESS SUCCESS",
  },
};

const InsightList = [
  {
    title: "THE COMPLETE BEGINNER'S GUIDE TO ECOMMERCE WEBSITE",
    author: "Marina Lu",
    date: "28.10.2020",
    read: "10 min",
    photo: rebrimg,
  },
  {
    title: "THE MAIN BENEFITS OF A PROFESSIONAL BUSINESS WEBSITE",
    author: "Ilia Bortnikov",
    date: "21.12.2021",
    read: "6 min",
    photo: stepsimg,
  },
  {
    title: "THE IMPORTANCE OF PROFESSIONAL WEB DESIGN FOR YOUR BUSINESS",
    author: "Ilia Bortnikov",
    date: "21.12.2021",
    read: "6 min",
    photo: stapa,
  },
  {
    title: "THE MAJOR STEPS INVOLVED IN PRODUCT DESIGN FOR A STARTUP",
    author: "Ilia Bortnikov",
    date: "21.12.2021",
    read: "4 min",
    photo: forimg,
  },
];

const InsightItem = ({ title, author, date, read, photo }) => {
  return (
    <div className="flex relative group flex-col cursor-pointer hover:text-primary-100 transition-all duration-200 items-center justify-center py-20 mb-32">
      <div className="absolute inset-0 opacity-10" style={{ background: `url('${photo}') center center no-repeat`, backgroundAttachment: "fixed", backgroundSize: "cover" }}></div>
      <div className="text-5xl text-center font-bold max-w-4xl">{title} </div>
      <div className="mt-8">
        <div className="text-2xl flex flex-col md:flex-row items-center text-center font-bold">
          {author}
          <span className="w-4 h-4 group-hover:bg-primary-100 transition-all duration-200 bg-black rounded-full mx-10"></span>
          {date}
          <span className="w-4 h-4 group-hover:bg-primary-100 transition-all duration-200 bg-black rounded-full mx-10"></span>
          {read}
        </div>
      </div>
    </div>
  );
};
export default function CaseStudies() {
  return (
    <div className="w-screen relative z-20 min-h-screen flex flex-col items-start justify-center">
      <PageHeader title="Insights" />
      <InnerHeadr head={pageDetails.head} bottom={pageDetails.bottom} />
      <div className="w-screen pt-20 pb-40 p-4">
        <div className="flex flex-col container mx-auto">
          {InsightList.map((item, idx) => {
            return <InsightItem title={item.title} author={item.author} key={idx} date={item.date} read={item.read} photo={item.photo} />;
          })}
        </div>
      </div>
      <Marquee cont="CASE STUDIES" />
    </div>
  );
}
